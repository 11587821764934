import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const StyledToast = styled(ToastContainer)`
  .Toastify__toast {
    padding: 8px 16px;
    margin: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    min-height: 74px;
    font-family: inherit;
    background-color: rgba(255, 255, 255, 0.8);
    color: ${({ theme }) => theme.palette.black[900]};

    .Toastify__toast-body > div {
      display: grid;
      grid-template-columns: 24px auto;
      gap: 12px;
      align-items: center;

      .text-container {
        text-align: left;
      }
    }
  }
`;
