import { useT } from '@transifex/react';
import useAuthentication from 'hooks/useAuthentication';
import { useIsRouterChanging } from 'hooks/useIsRouterChanging';
import PageLayout from 'layouts/PageLayout';
import { GetServerSideProps } from 'next';
import { Session, getServerSession } from 'next-auth';
import { signOut } from 'next-auth/react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { authOptions } from './api/auth/[...nextauth]';
import { getMyProfile } from 'utils/fetch';

const Login = dynamic(() => import('components/Login'), { ssr: false });
const Loader = dynamic(() => import('components/Loader'), { ssr: false });

const LoginPage: React.FC = () => {
  const router = useRouter();
  const t = useT();
  const { query } = router;
  const navigateTo = query.navigate as string;
  const credentialsLoginError = query.error as string;

  const isRouterChanging = useIsRouterChanging();

  const { isUserAuthenticated, isSignUpCompleted, isLoading: isAuthLoading } = useAuthentication();

  useEffect(() => {
    if (isUserAuthenticated && !isSignUpCompleted) {
      signOut({ redirect: false }).then(() =>
        toast.error(t('The sign-up process is waiting for your final steps in the mobile app.'))
      );
    }
    if (credentialsLoginError)
      toast.error(t('Sorry! It seems the account or password is incorrect.'));
  }, [credentialsLoginError, isSignUpCompleted, isUserAuthenticated, t]);

  if (isAuthLoading || isRouterChanging) return <Loader />;

  return (
    <>
      <Head>
        <title>{`Login | WeAre8`}</title>
      </Head>
      <PageLayout withoutPadding>
        <Login navigateTo={navigateTo ?? '/login'} />
      </PageLayout>
    </>
  );
};

export default LoginPage;

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = (await getServerSession(context.req, context.res, authOptions)) as Session | null;

  if (!session) return { props: {} };

  let loggedInUsername = session.user.username;

  if (!loggedInUsername) {
    const accessToken = session.user.accessToken as string;
    const userProfile = await getMyProfile(accessToken);
    if (userProfile) loggedInUsername = userProfile.username;
  }

  return loggedInUsername
    ? { redirect: { destination: `/@${loggedInUsername}`, permanent: false } }
    : { props: {} };
};
