import { ThemeAnchor, ThemeButton, ThemeLink } from 'assets/styles/theme';
import ButtonLoader from 'components/ButtonLoader';
import { LinkProps } from 'next/link';
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, ReactNode } from 'react';

type BaseProps = {
  children: ReactNode | ReactNode[];
  className?: string;
  styleType:
    | 'primary'
    | 'secondary'
    | 'ghost'
    | 'tertiary'
    | 'quaternary'
    | 'login'
    | 'bottom-sheet';
  isLoading?: boolean;
  loaderColor?: 'black' | 'white';
  size?: 'small' | 'regular';
};

type ButtonAsButton = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
    as?: 'button';
  };

type ButtonAsLink = BaseProps &
  Omit<LinkProps, keyof BaseProps> & {
    as: 'Link';
    rel?: string;
    target?: string;
    disabled?: boolean;
  };

type ButtonAsExternal = BaseProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseProps> & {
    as: 'externalLink';
  };

type ButtonProps = ButtonAsButton | ButtonAsExternal | ButtonAsLink;

const Button: React.FC<ButtonProps> = (props) => {
  const allClassNames = `${props.styleType ? props.styleType : ''} ${
    props.size ? props.size : ''
  } ${props.className ? props.className : ''}`;

  if (props.as === 'Link') {
    const { className, styleType, as, isLoading, loaderColor, disabled, ...rest } = props;
    return (
      <ThemeLink className={allClassNames + `${disabled ? 'disabled' : ''}`} {...rest}>
        {props.children}
      </ThemeLink>
    );
  } else if (props.as === 'externalLink') {
    const { className, styleType, as, isLoading, loaderColor, ...rest } = props;
    return (
      <ThemeAnchor className={allClassNames} target="_blank" rel="noopener noreferrer" {...rest}>
        {props.children}
      </ThemeAnchor>
    );
  } else {
    const {
      className,
      styleType,
      as,
      isLoading,
      loaderColor,
      size,
      type = 'button',
      ...rest
    } = props;
    return (
      <ThemeButton className={allClassNames} {...rest} type={type}>
        {isLoading ? <ButtonLoader black={loaderColor === 'black'} /> : props.children}
      </ThemeButton>
    );
  }
};

export default Button;
